document.addEventListener('turbo:load', function () {
  // configured in, copy-pasted and deminified from:
  // https://workbrew.unthread.io/dashboard/settings/in-app-chat
  window.$unthreadInAppChatSettings = {
    baseUrl: 'https://workbrew.unthread.io',
    widgetId: 'daa5fe69-d26a-41ed-b886-fdddd18cd52c'
  }

  const handleChat = function () {
    handleChat.processArguments(arguments)
  }
  handleChat.queue = []
  handleChat.processArguments = function (arguments_) {
    handleChat.queue.push(arguments_)
  }

  // add these aliases for the upstream Unthread script to use
  handleChat.c = handleChat.processArguments
  handleChat.q = handleChat.queue

  if (!window.$unthread) {
    window.$unthread = {}
  }

  window.$unthread.inAppChat = handleChat

  const scriptElement = document.createElement('script')
  scriptElement.src = window.$unthreadInAppChatSettings.baseUrl +
    '/widget/js/wrapper.js'
  scriptElement.defer = true
  scriptElement.async = true

  const firstScript = document.querySelector('script')
  firstScript.parentNode.insertBefore(scriptElement, firstScript)
  // end copy-paste deminified

  const meta = (name) =>
    document.querySelector(`meta[name=${name}]`)?.content || ''
  if (meta('uid')) {
    window.$unthread.inAppChat('start', {
      user: {
        name: meta('username'),
        email: meta('email')
      }
    })
  }
})
